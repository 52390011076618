<template>
  <div class="header-container header-bk">
    <div class="inner-container">
      <div class="logo-box">
        <img :src="logoIcon" class="logo" @click="jumpToHome" />
      </div>
      <ul class="menu-list">
        <router-link
          exact
          v-for="item in linkList"
          :to="item.path"
          :key="item.path"
          class="list-item"
          tag="li"
        >
          <span>{{ item.name }}</span>
          <span class="list-item-line"></span>
          <span
            class="active-item-line"
            :class="isNewsDetails && item.path === '/news' ? 'show' : ''"
          ></span>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import logoIcon from "@/assets/img/header/logo.svg";
export default {
  name: "headerView",
  components: {},
  props: {},
  data() {
    return {
      // icon
      logoIcon,
      linkList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "交易平台",
          path: "/product-service",
        },
        // {
        //   name: "党建文化",
        //   path: "/party-building-culture",
        // },
        {
          name: "会员服务",
          path: "/shareholder-introduction",
        },
        {
          name: "新闻资讯",
          path: "/news",
        },
        {
          name: "关于我们",
          path: "/about-us",
        },
      ],
      isNewsDetails: false,
    };
  },
  mounted() {},
  watch: {
    $route(nV) {
      let { name } = nV;
      if (name === "NewsDetails") {
        this.isNewsDetails = true;
      } else {
        this.isNewsDetails = false;
      }
    },
  },
  computed: {},
  methods: {
    jumpToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 66px;
  background: transparent;
  border: none;
  transition: background 0.5s ease, border 0.5s ease;
  box-shadow: 0px 4px 10px 0px #000000 0.1;

  .inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .logo-box {
      width: 258px;
      height: 66px;
      background: #0057a7;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo {
      width: 217px;
      height: 45px;
      cursor: pointer;
    }

    .menu-list {
      display: flex;
      margin-left: 80px;
    }

    .list-item {
      position: relative;
      cursor: pointer;
      font-size: 16px;
      font-family: MiSans-Normal, MiSans;
      font-weight: 400;
      color: #252b3a;
      line-height: 66px;
    }

    .active-item-line {
      opacity: 0;
    }

    .list-item-line {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: width 0.3s 0.1s ease;
      width: 0;
      height: 3px;
      background: #0057a7;
    }

    .list-item + .list-item {
      margin-left: 64px;
    }
    .active-menu-item {
      color: #0057a7 !important;
      .active-item-line {
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background: #0057a7;
      }
    }
    .show {
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      background: #0057a7;
    }
  }
}

.header-bk {
  background: #fff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);

  .list-item {
    color: #191b24 !important;
  }

  .list-item:hover {
    color: #0057a7 !important;

    .list-item-line {
      width: 100%;
    }
  }

  ::v-deep .active-menu-item {
    color: #0057a7 !important;
    .list-item-line {
      opacity: 0;
    }

    .active-item-line {
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      background: #0057a7;
    }
  }
}
</style>
