<template>
  <div id="app">
    <Vheader v-if="showHeaderFooter"></Vheader>
    <router-view />
    <Vfooter v-if="showHeaderFooter"></Vfooter>
    <div class="back-top" v-show="showIcon"></div>
  </div>
</template>

<script>
const Elevator = require("elevator.js");
import Vfooter from "@/components/footer.vue";
import Vheader from "@/components/header.vue";
export default {
  components: {
    Vfooter,
    Vheader,
  },
  data() {
    return {
      showIcon: false,
      scrollTop: 0,
      showHeaderFooter: true,
    };
  },
  created() {
    var user = navigator.userAgent;
    //android端
    var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
    //ios端
    var isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      window.location.href = "https://www.zjdex.com/mobile";
    } else if (isiOS) {
      window.location.href = "https://www.zjdex.com/mobile";
    }
  },
  mounted() {
    this.setBackTop();
    window.addEventListener("scroll", this.$debounce(this.listenScroll, 100));
  },
  methods: {
    // 设置回到顶部
    setBackTop() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.showIcon = this.showBackTop(scrollTop);
      this.scrollTop = scrollTop;
      // eslint-disable-next-line
      var elevator = new Elevator({
        element: document.querySelector(".back-top"),
        duration: 800, // milliseconds
      });
    },
    // 显示回到顶部
    showBackTop(scrollTop) {
      if (scrollTop >= 600) {
        return true;
      } else {
        return false;
      }
    },
    //显示回到顶部封装
    listenScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
      this.showIcon = this.showBackTop(scrollTop);
    },
  },
  watch: {
    $route(nV, oV) {
      let { name } = nV;
      if (name === "notFound") {
        this.showHeaderFooter = false;
      } else {
        this.showHeaderFooter = true;
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  .back-top {
    z-index: 50;
    cursor: pointer;
    position: fixed;
    bottom: 15%;
    right: 30px;
    width: 50px;
    height: 50px;
    background: no-repeat 0 center / cover;
    background-image: image-set(
      url("./assets/img/backtop.png") 1x,
      url("./assets/img/backtop@2x.png") 2x
    );
    background-image: url("./assets/img/backtop.png");
    box-shadow: 0px 6px 26px 0px rgba(0, 51, 98, 0.2);
    border-radius: 100%;
  }
  .back-top:hover {
    z-index: 50;
    cursor: pointer;
    position: fixed;
    right: 30px;
    width: 50px;
    height: 50px;
    background: no-repeat 0 center / cover;
    background-image: image-set(url("./assets/img/backtop-active.png") 1x);
    background-image: url("./assets/img/backtop-active.png");
    box-shadow: 0px 6px 26px 0px rgba(0, 51, 98, 0.2);
    border-radius: 100%;
  }
}
</style>
